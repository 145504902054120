@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
@import url(http://fonts.cdnfonts.com/css/portico);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{font-family:"Franklin Gothic Demi";src:url("/static/media/Franklin Gothic Demi Regular.afd01b7c.ttf")}html,body{padding:0;margin:0}html{height:100%}body,#root{min-height:100%;padding:0 0 50px 0;margin:0;height:100vh}body{background:#000}a{text-decoration:none}.site-wrapper{max-width:1400px;position:relative;margin:auto;display:flex;font-family:"Franklin Gothic Demi", sans-serif;height:100%}.home-division{display:flex;margin-top:75px;padding:20px}@media (max-width: 700px){.home-division{display:block}}.home-division .greeting{color:#fff;padding:25px;font-family:'Patrick Hand', cursive;font-size:18px;max-width:300px}.portrait{width:300px}@media (max-width: 700px){.portrait{width:150px;float:left;padding:20px}}.portrait img{width:300px}@media (max-width: 700px){.portrait img{width:150px}}

