@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('http://fonts.cdnfonts.com/css/portico');
@font-face {
  font-family: "Franklin Gothic Demi";
  src: url('./fonts/Franklin Gothic Demi Regular.ttf');
}

html, body{
  padding: 0;
  margin: 0;
}
html{
  height: 100%;
}
body, #root{
  min-height: 100%;
  padding: 0 0 50px 0;
  margin: 0;
  height:100vh;
}
body {
  background: #000;
}

a {
  text-decoration: none;
}
.site-wrapper {
  max-width: 1400px;
  position: relative;
  margin: auto;
  display: flex;
  font-family: "Franklin Gothic Demi", sans-serif;
  height: 100%;
}

.home-division {
 display: flex;
  margin-top: 75px;
  padding: 20px;
  @media(max-width: 700px) {
    display: block;
  }
  .greeting {
    color: #fff;
    padding: 25px;
    font-family: 'Patrick Hand', cursive;
    font-size: 18px;
    max-width: 300px;
  }
}

.portrait {
    width: 300px;
  @media(max-width: 700px) {
    width: 150px;
    float: left;
    padding: 20px;
  }
  img {

      width: 300px;

    @media(max-width: 700px) {
      width: 150px;
    }

  }
}